import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`Semantic HTML provides meaning to the content of a web page. It involves using the correct HTML element for the job. Semantic HTML breaks the page up into meaningful sections.`}</p>
    <h2>{`Why?`}</h2>
    <p>{`Many groups of people benefit from properly used semantic HTML. Using the correct elements allows assistive technology to accurately convey the purpose of the content to the user. Without it, they will not be able to navigate easily. Other benefits of using semantic HTML are SEO and code readability.`}</p>
    <h2>{`How to test for semantic HTML`}</h2>
    <p>{`HTML validators, automated tooling, unit tests, and linters are a few ways to test for proper HTML. Most automated tools can catch some incorrect usage of HTML elements. For example, if heading levels are out of order, it will be reported as a violation. Keep in mind that `}<a parentName="p" {...{
        "href": "https://www.essentialaccessibility.com/blog/automated-accessibility-testing-tools-how-much-do-scans-catch#:~:text=For%20WCAG%202.1%20AA%20tests,by%20yes%20or%20no%20conditions."
      }}>{`automated tools only catch around 20-25% of accessibility errors`}</a>{`.`}</p>
    <p>{`This is a subset of the tools available:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://validator.w3.org/"
        }}>{`HTML validator`}</a></li>
      <li parentName="ul">{`Automated tools`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.deque.com/axe/devtools/"
            }}>{`aXe DevTools`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/GoogleChrome/lighthouse"
            }}>{`Lighthouse`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Linting`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/primer/view_components/blob/main/docs/content/linting.md"
            }}>{`Primer ViewComponents`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/primer/react/blob/main/contributor-docs/CONTRIBUTING.md#linting"
            }}>{`Primer React`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/jsx-eslint/eslint-plugin-jsx-a11y"
            }}>{`eslint-plugin-jsx-a11y`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/github/erblint-github"
            }}>{`erblint-github`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`Guidelines`}</h2>
    <h3>{`For designers`}</h3>
    <p>{`In your designs, annotate what HTML elements should be used for various parts of the design, if appropriate. Understand that most designs are achievable with CSS alone, agnostic of the HTML elements used.`}</p>
    <h3>{`For engineers`}</h3>
    <p>{`Think about the content that will populate an element in order to determine what HTML element should be used. Are you building a navigation? Use the `}<inlineCode parentName="p">{`<nav>`}</inlineCode>{` element instead of nested `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` elements. You may need to add interactivity to more complex elements, such as `}<inlineCode parentName="p">{`<dialog>`}</inlineCode>{`. Some elements may require additional ARIA attributes to convey things such as state, but be careful to `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/using-aria/#notes2"
      }}>{`use these only when necessary`}</a>{`.`}</p>
    <h2>{`Common mistakes`}</h2>
    <ul>
      <li parentName="ul">{`Using improper elements to achieve the desired visual style`}
        <ul parentName="li">
          <li parentName="ul">{`Example: using an `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{` tag for a heading that should be an `}<inlineCode parentName="li">{`<h3>`}</inlineCode>{` because the visual style of the `}<inlineCode parentName="li">{`<h1>`}</inlineCode>{` is desired`}</li>
          <li parentName="ul">{`Solve: use CSS for visual styling`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Using the `}<inlineCode parentName="li">{`title`}</inlineCode>{` attribute`}
        <ul parentName="li">
          <li parentName="ul">{`It is inaccessible for many users, such as touch-only and keyboard-only users. Do not use.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Using improper markup`}
        <ul parentName="li">
          <li parentName="ul">{`Example: placing text in a `}<inlineCode parentName="li">{`<div>`}</inlineCode>{` instead of a `}<inlineCode parentName="li">{`<p>`}</inlineCode></li>
          <li parentName="ul">{`Solve: use the proper element to depict the content that is inside`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Using incorrect ARIA`}
        <ul parentName="li">
          <li parentName="ul">{`Example: using an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` on a non-interactive element such as a `}<inlineCode parentName="li">{`<div>`}</inlineCode></li>
          <li parentName="ul">{`Solve: only use ARIA when necessary, and if you `}<em parentName="li">{`can`}</em>{` use a native HTML element, you should. `}<a parentName="li" {...{
              "href": "https://www.w3.org/TR/using-aria/#notes2"
            }}>{`More information on ARIA`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Duplicate IDs`}
        <ul parentName="li">
          <li parentName="ul">{`Example: two elements on the page with `}<inlineCode parentName="li">{`id="anchor"`}</inlineCode></li>
          <li parentName="ul">{`Solve: use unique IDs for every element on the page`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Decorative elements not marked as decorative`}
        <ul parentName="li">
          <li parentName="ul">{`Example: an `}<inlineCode parentName="li">{`<hr>`}</inlineCode>{` element not being marked as decorative`}</li>
          <li parentName="ul">{`Solve: add `}<inlineCode parentName="li">{`role="presentation"`}</inlineCode>{` or `}<inlineCode parentName="li">{`aria-hidden="true"`}</inlineCode>{` to elements that are for decorative purposes only`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Reading order`}</h2>
    <p>{`Screen readers and other assistive technologies convey the information to the user in the order that it is marked up in the code, not necessarily how it looks on the page. Ensure the order matches a logical order of information presented. One way to verify this is to view the page without styles and see if the order of content is logical.`}</p>
    <h2>{`Additional resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/github/accessibility/blob/main/docs/deep-dive-notes/2022-02-16-semantic-html-and-aria.md"
        }}>{`Deep Dive: Semantic HTML and ARIA`}</a>{` (Only accessible to GitHub staff)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element"
        }}>{`List of semantic elements`}</a></li>
    </ul>
    <h3>{`Related WCAG guidelines and success criteria`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG21/#meaningful-sequence"
        }}>{`1.3.2 Meaningful Sequence`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG21/#section-headings"
        }}>{`2.4.10 Section Headings`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG21/#name-role-value"
        }}>{`4.1.2 Name, Role, Value`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      